var temeda = temeda || {}; // eslint-disable-line no-use-before-define
temeda.core = temeda.core || {};

temeda.core.datasetMonitor = (function($) {
  "use strict";

  var data = {};
  var eventCallbacks = {};

  function create(datasetName, identityField, labelField) {
    if (datasetName && identityField && !data[datasetName]) {
      data[datasetName] = {};
      data[datasetName].identityField = identityField;
      if (labelField) {
        data[datasetName].labelField = labelField;
      }
      return datasetName;
    } else {
      return null;
    }
  }

  function add(datasetName, values) {
    if (!datasetName || !data[datasetName] || !values || typeof values !== "object") {
      return 0;
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    var identityField = data[datasetName].identityField;
    var itemsAdded = [];

    values.forEach(function(value) {
      if (
        value &&
        value[identityField] !== undefined &&
        value[identityField] !== null &&
        !data[datasetName][value[identityField]]
      ) {
        var safeValue = JSON.parse(JSON.stringify(value));
        data[datasetName][value[identityField]] = safeValue;
        itemsAdded.push(safeValue);
      }
    });

    if (itemsAdded.length > 0) {
      onAdd(datasetName, itemsAdded);
      return itemsAdded.length;
    } else {
      return 0;
    }
  }

  function update(datasetName, values) {
    if (!datasetName || !data[datasetName] || !values || typeof values !== "object") {
      return 0;
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    var identityField = data[datasetName].identityField;
    var itemsUpdated = [];

    values.forEach(function(value) {
      if (
        value &&
        value[identityField] !== undefined &&
        value[identityField] !== null &&
        data[datasetName][value[identityField]]
      ) {
        var safeValue = JSON.parse(JSON.stringify(value));
        var oldValue = data[datasetName][value[identityField]];
        data[datasetName][value[identityField]] = safeValue;

        if (JSON.stringify(oldValue) !== JSON.stringify(safeValue)) {
          itemsUpdated.push(safeValue);
        }
      }
    });

    if (itemsUpdated.length > 0) {
      onUpdate(datasetName, itemsUpdated);
      return itemsUpdated.length;
    } else {
      return 0;
    }
  }

  function addUpdate(datasetName, values) {
    if (!datasetName || !data[datasetName] || !values || typeof values !== "object") {
      return {
        add: 0,
        update: 0,
      };
    }

    if (!Array.isArray(values)) {
      values = [values];
    }

    var identityField = data[datasetName].identityField;
    var itemsAdded = [];
    var itemsUpdated = [];
    var safeValue;

    values.forEach(function(value) {
      if (value && value[identityField] !== undefined && value[identityField] !== null) {
        safeValue = JSON.parse(JSON.stringify(value));
        if (data[datasetName][value[identityField]]) {
          // var oldValue = data[datasetName][value[identityField]];

          //if (JSON.stringify(oldValue) !== JSON.stringify(safeValue)) {
          if (data[datasetName][value[identityField]]["Updated_On"] !== value.Updated_On) {
            itemsUpdated.push(safeValue);
          } else {
            //check asset-device in case web sockets are not updating
            if (datasetName === "assets") {
              if (
                data.assets[value[identityField]].Device &&
                data.assets[value[identityField]].Device.Last_Communication !== value.Device.Last_Communication
              ) {
                //Last communication doesn't match, send out update event
                itemsUpdated.push(safeValue);
              }
            }
          }
        } else {
          itemsAdded.push(safeValue);
        }
        data[datasetName][value[identityField]] = safeValue;
      }
    });

    if (itemsAdded.length > 0) {
      onAdd(datasetName, itemsAdded);
    }
    if (itemsUpdated.length > 0) {
      onUpdate(datasetName, itemsUpdated);
    }

    return {
      add: itemsAdded.length,
      update: itemsUpdated.length,
    };
  }

  function remove(datasetName, ids) {
    if (!datasetName || !data[datasetName]) {
      return 0;
    }

    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    var itemsRemoved = [];

    ids.forEach(function(id) {
      if (id !== undefined && id !== null && data[datasetName][id]) {
        itemsRemoved.push(JSON.parse(JSON.stringify(data[datasetName][id])));
        delete data[datasetName][id];
      }
    });

    if (itemsRemoved.length > 0) {
      onRemove(datasetName, itemsRemoved);
      return itemsRemoved.length;
    } else {
      return 0;
    }
  }

  /**
   *
   * @param {string} datasetName - Name of the dataset
   */
  function clear(datasetName) {
    if (!datasetName || !data[datasetName]) {
      return 0;
    }
    delete data[datasetName];
    onClear(datasetName);
    return 1;
  }

  function getDatasetNames() {
    var datasetNames = [];

    Object.keys(data).forEach(function(key) {
      datasetNames.push(key);
    });

    return datasetNames;
  }

  function datasetExists(datasetName) {
    return data[datasetName] !== undefined && data[datasetName] !== null;
  }

  function getDatasetIdentityField(datasetName) {
    if (!datasetName) {
      return null;
    }

    if (data[datasetName] && data[datasetName].identityField) {
      return data[datasetName].identityField;
    } else {
      return null;
    }
  }

  function getDatasetLabelField(datasetName) {
    if (!datasetName) {
      return null;
    }

    if (data[datasetName] && data[datasetName].labelField) {
      return data[datasetName].labelField;
    } else {
      return null;
    }
  }

  function get(datasetName) {
    if (!datasetName) {
      return null;
    }

    if (data[datasetName]) {
      var values = [];

      //create an array of the objects
      Object.keys(data[datasetName]).forEach(function(key) {
        if (key !== "identityField" && key !== "labelField") {
          values.push(data[datasetName][key]);
        }
      });

      var clonedValues = JSON.parse(JSON.stringify(values));

      //if a labelfield is present, sort the array of objects by the labelfield
      if (getDatasetLabelField(datasetName)) {
        var label = getDatasetLabelField(datasetName);
        clonedValues = _.sortBy(clonedValues, function(i) {
          return i[label] && isNaN(i[label]) ? i[label].toLowerCase() : i[label];
        });
      }

      return clonedValues;
    } else {
      return null;
    }
  }

  // This returns the dataset, but as an object (instead of an array).
  //   The properties on the object are the values of the identity field.
  //   This object will allow easy lookup to specific items in the dataset (i.e. dataset['123'])
  function getAsObject(datasetName) {
    if (!datasetName) {
      return null;
    }

    if (data[datasetName]) {
      // var copy = _.cloneDeep(data[datasetName]);
      // var nodesArray = data[datasetName];
      var copy = JSON.parse(JSON.stringify(data[datasetName]));
      delete copy.identityField;
      delete copy.labelField;
      return copy;
    } else {
      return null;
    }
  }

  function getSingle(datasetName, id) {
    if (!datasetName || !data[datasetName] || id === undefined || id === null) {
      return null;
    }

    if (data[datasetName][id]) {
      return JSON.parse(JSON.stringify(data[datasetName][id]));
    } else {
      return null;
    }
  }

  function on(events, datasetName, callbacks) {
    if (!eventCallbacks[datasetName]) {
      eventCallbacks[datasetName] = {};
    }

    var items = events.split(" ");

    items.forEach(function(item) {
      if (item) {
        if (!eventCallbacks[datasetName][item]) {
          eventCallbacks[datasetName][item] = $.Callbacks();
        }

        eventCallbacks[datasetName][item].add(callbacks);
      }
    });
  }

  function off(events, datasetName, callbacks) {
    if (eventCallbacks[datasetName]) {
      var items = events.split(" ");

      items.forEach(function(item) {
        if (item) {
          if (eventCallbacks[datasetName][item]) {
            eventCallbacks[datasetName][item].remove(callbacks);
          }
        }
      });
    }
  }

  function one(events, datasetName, callbacks) {
    if (eventCallbacks[datasetName]) {
      var items = events.split(" ");

      items.forEach(function(item) {
        if (item) {
          if (!eventCallbacks[datasetName][item]) {
            eventCallbacks[datasetName][item] = $.Callbacks();
          }

          var once = function(data) {
            if (Array.isArray(callbacks)) {
              callbacks.forEach(function(callback) {
                callback(data);
              });
            } else {
              callbacks(data);
            }

            eventCallbacks[datasetName][item].remove(once);
          };

          eventCallbacks[datasetName][item].add(once);
        }
      });
    }
  }

  function onAdd(datasetName, data) {
    if (eventCallbacks[datasetName] && eventCallbacks[datasetName].add) {
      setTimeout(function() {
        eventCallbacks[datasetName].add.fire(data);
      }, 0);
    }
  }

  function onUpdate(datasetName, data) {
    if (eventCallbacks[datasetName] && eventCallbacks[datasetName].update) {
      setTimeout(function() {
        eventCallbacks[datasetName].update.fire(data);
      }, 0);
    }
  }

  function onRemove(datasetName, data) {
    if (eventCallbacks[datasetName] && eventCallbacks[datasetName].remove) {
      setTimeout(function() {
        eventCallbacks[datasetName].remove.fire(data);
      }, 0);
    }
  }

  function onClear(datasetName) {
    if (eventCallbacks[datasetName] && eventCallbacks[datasetName].clear) {
      setTimeout(function() {
        eventCallbacks[datasetName].clear.fire();
      }, 0);
    }
  }

  return {
    create: create,
    add: add,
    update: update,
    addUpdate: addUpdate,
    remove: remove,
    clear: clear,
    getDatasetNames: getDatasetNames,
    datasetExists: datasetExists,
    getDatasetIdentityField: getDatasetIdentityField,
    getDatasetLabelField: getDatasetLabelField,
    get: get,
    getAsObject: getAsObject,
    getSingle: getSingle,
    on: on,
    off: off,
    one: one,
  };
})(jQuery);

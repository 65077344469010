var temeda = temeda || {}; // eslint-disable-line no-use-before-define
temeda.core = temeda.core || {};

temeda.core.security = (function($) {
  "use strict";
  var event;
  if (temeda.core.event) {
    //event is needed for accordion to load date format when user is logged in but this file is used where event may not
    //be loaded so this is to prevent errors
    event = temeda.core.event();
  } else {
    event = {
      on: "",
    };
  }

  function getAuthToken() {
    let isSsoCookie = temeda.core.utils.getCookie("isSso");
    if (isSsoCookie === "true") {
      let creds = {
        scheme: "Bearer",
      };
      creds.token = temeda.core.utils.getCookie("token");
      let userToImpersonate = temeda.core.utils.getCookie("imper_user");
      if (userToImpersonate) {
        creds.impersonate = atob(decodeURIComponent(userToImpersonate));
      }
      return creds;
    } else {
      let username = atob(temeda.core.utils.getCookie("email"));
      let password = atob(temeda.core.utils.getCookie("password"));
      let plainTextToken = `${username}:${password}`;

      let userToImpersonate = temeda.core.utils.getCookie("imper_user");
      if (userToImpersonate) {
        const decodedImpersonate = atob(decodeURIComponent(userToImpersonate));
        plainTextToken += `:${decodedImpersonate}`;
      }
      let token = btoa(plainTextToken);
      return {
        scheme: "Basic",
        token,
      };
    }
  }

  function loadCredentials(self) {
    //login cookie is a session cookie - if user logged out or closed browser - make them log in again
    var logInCookie = temeda.core.utils.getCookie("logIn");

    if (logInCookie === "true") {
      var isSsoCookie = temeda.core.utils.getCookie("isSso");
      if (isSsoCookie === "true") {
        self.credentials = {};
        self.credentials.isSsoUser = true;
        self.credentials.token = temeda.core.utils.getCookie("token");
        let decodedImpersonate = "";
        let encodedImpersonate = "";
        if (temeda.core.utils.getCookie("imper_user")) {
          try {
            encodedImpersonate = decodeURIComponent(temeda.core.utils.getCookie("imper_user"));
            decodedImpersonate = window.atob(encodedImpersonate);
          } catch (e) {
            console.error(e);
            //it wasn't encoded
            decodedImpersonate = encodedImpersonate;
          }
          self.credentials.impersonate = decodedImpersonate;
        }
      } else {
        self.credentials = {};
        self.credentials.requestUrl = temeda.core.utils.getCookie("requestUrl");
        self.credentials.username = temeda.core.utils.getCookie("email");
        self.credentials.password = temeda.core.utils.getCookie("password");
        let decodedImpersonate = "";
        let encodedImpersonate = "";

        if (temeda.core.utils.getCookie("imper_user")) {
          try {
            encodedImpersonate = decodeURIComponent(temeda.core.utils.getCookie("imper_user"));
            decodedImpersonate = window.atob(encodedImpersonate);
          } catch (e) {
            console.error(e);
            //it wasn't encoded
            decodedImpersonate = encodedImpersonate;
          }
        }
        self.credentials.impersonate = decodedImpersonate;
        self.credentials.enterprise = temeda.core.utils.getCookie("enterprise");
      }
    } else {
      window.location.replace("/login.html");
    }
  }

  function checkLogIn() {
    //login cookie is a session cookie - if user logged out or closed browser - make them log in again
    var logInCookie = temeda.core.utils.getCookie("logIn");
    if (!logInCookie) {
      window.close();
    }
  }

  // function saveCredentials(self, expireHours) {
  //   if (self.credentials) {
  //     if (self.credentials.username) {
  //       temeda.core.utils.setCookie("email", self.credentials.username, expireHours);
  //     } else {
  //       temeda.core.utils.deleteCookie("email");
  //     }

  //     if (self.credentials.password) {
  //       temeda.core.utils.setCookie("password", self.credentials.password, expireHours);
  //     } else {
  //       temeda.core.utils.deleteCookie("password");
  //     }

  //     if (self.credentials.impersonate) {
  //       temeda.core.utils.setCookie("imper_user", self.credentials.impersonate, expireHours);
  //     } else {
  //       temeda.core.utils.deleteCookie("imper_user");
  //     }
  //     if (self.credentials.enterprise) {
  //       temeda.core.utils.setCookie("enterprise", self.credentials.enterprise, expireHours);
  //     } else {
  //       temeda.core.utils.deleteCookie("enterprise");
  //     }
  //   } else {
  //     removeLogInCookies();
  //   }
  // }

  function removeLogInCookies(all) {
    if (temeda.core.utils.getCookie("isSso") === "true") {
      temeda.core.utils.deleteCookie("isSso");
      temeda.core.utils.deleteCookie("token");
    }
    if (temeda.core.utils.getCookie("imper_user") === null) {
      temeda.core.utils.deleteCookie("logIn");
      temeda.core.utils.deleteCookie("logOff");
      if (temeda.core.utils.getCookie("remember") !== "true") {
        temeda.core.utils.deleteCookie("email");
        temeda.core.utils.deleteCookie("password");
        temeda.core.utils.deleteCookie("enterprise");
      }
    } else {
      //if user is impersonated, only delete imper cookie unless all
      if (all) {
        //called at midnight
        temeda.core.utils.deleteCookie("logIn");
        temeda.core.utils.deleteCookie("logOff");
      }
      temeda.core.utils.deleteCookie("imper_user");
    }
  }

  function authenticate(self) {
    var deferred = $.Deferred();
    temeda.core.data.utils
      .loadFromOpeningController(0)
      .done(data => {
        if (data && data.au && data.au.idUser) {
          //change lowercase m to upper case M
          if (data.au.Date_Format) {
            let correctedDateFormat = data.au.Date_Format.replace(/m/g, "M");
            data.au.Date_Format = correctedDateFormat;
          }
          self.user = data.au;
          deferred.resolve(data);
          if (event && event.fire) {
            //tell accordion what the user's date and time format is
            event.fire("userloaded", data.au);
          }

          // Setup multi-customer access
          if (data.availableCustomers) {
            temeda.core.security.user.availableCustomers = data.availableCustomers;
            var topmostCustomers = data.availableCustomers.filter(ac => ac.IsTopmostCustomer);
            if (topmostCustomers && topmostCustomers.map(c => c.idCustomer).includes(data.au.idCustomer)) {
              temeda.core.security.user.isInTopmostCustomer = true;
            }
          }
        } else {
          self.user = null;
          deferred.reject("Authentication denied.");
        }
      })
      .fail(() => {
        self.user = null;
        deferred.reject("Authentication denied.");
      });

    return deferred.promise();
  }

  function logIn(usern, pwd, remember, logoff, redirect) {
    //simple username password check without filling the user object

    var options = {};
    options.type = "GET";
    options.cache = false;
    options.url = "../api/users?a=getuser";
    options.async = true;
    var encodedCredentials = null;
    var userData = {};

    if (!window) {
      console.error("No window on login");
    }

    encodedCredentials = window.btoa(usern + ":" + pwd);

    if (!encodedCredentials) {
      console.error("Login - base64 conversion failed");
    }

    options.headers = {
      Authorization: "Basic " + encodedCredentials,
      "Access-Control-Allow-Origin": true,
    };

    $.ajax(options)
      .done(function(data) {
        if (data && data.idUser) {
          userData.user = data;
        } else {
          userData.user = null;
        }
        //if remember me then set cookies to expire in twentyyears otherwise make them session cookies
        if (remember) {
          var twentyyears = 20 * 365 * 24;
          temeda.core.utils.setCookie("email", window.btoa(usern), twentyyears);
          temeda.core.utils.setCookie("password", window.btoa(pwd), twentyyears);
          temeda.core.utils.setCookie("remember", remember, twentyyears);
        } else {
          temeda.core.utils.setCookie("email", window.btoa(usern));
          temeda.core.utils.setCookie("password", window.btoa(pwd));
          temeda.core.utils.setCookie("remember", remember);
        }
        temeda.core.utils.setCookie("logIn", true); //session variable to track if user is logged in
        temeda.core.utils.setCookie("logOff", logoff); //session variable to get logOff location

        //window.location = 'opening.html';
        window.location = "../" + temeda.core.security.userRole.homepage(userData.user);
      })
      .fail(() => {
        $("#loginSubmit").html("Log In");
        temeda.core.message.error("Login failed.", "Please verify your Username and Password.", () =>
          redirect ? window.location.replace("/login.html") : false,
        );
      });
  }

  function logOut(self) {
    var logOffLocation = "/login.html"; //default

    //if superuser log in remove impersonate non admin cookie
    var isSuperUser = temeda.core.utils.getCookie("is_super_user");
    if (isSuperUser) {
      temeda.core.utils.deleteCookie("imper_non_admin");
    }

    var isEnterprise = temeda.core.utils.getCookie("enterprise");
    var impersonatePath = temeda.core.utils.getCookie("impersonate_from");
    // check non admin impersonate email cookie
    var getNonAdminImpersonate = temeda.core.utils.getCookie("imper_non_admin");

    var impersonate = self.credentials.impersonate ? self.credentials.impersonate : null;

    if (impersonate !== null && isEnterprise) {
      logOffLocation = `${impersonatePath}?v=${temeda.core.utils.getVersion()}`; //impersonating, so go back to mgmt page
      temeda.core.utils.deleteCookie("impersonate_from");
      temeda.core.utils.deleteCookie("imper_non_admin");
    } else if (impersonate !== null && !getNonAdminImpersonate) {
      logOffLocation = "./management?v=" + temeda.core.utils.getVersion(); //impersonating, so go back to mgmt page
      temeda.core.utils.deleteCookie("is_super_user");
    } else if (getNonAdminImpersonate && !isEnterprise) {
      logOffLocation = "/opening.html?v=" + temeda.core.utils.getVersion(); //impersonating, so go back to mgmt page
      temeda.core.utils.deleteCookie("imper_non_admin");
    } else {
      // goback to login page if not impersonate anybody
      logOffLocation = temeda.core.utils.getCookie("logOff") || "/login.html";
    }
    self.credentials = {};
    removeLogInCookies();
    window.location = logOffLocation;
    temeda.menuListeners.closeTabs(); // close maintenance tabs and reports
    temeda.core.utils.closeUtilsTabs(); // close report tabs that come from uils generated
    temeda.component.map.markerInfoWindow.closeTabsFromMarkers(); // close tabs that comes from info marker
  }

  function switchCompany(idCustomer) {
    temeda.core.spinner.show();

    var uriPath = `users/${temeda.core.security.user.idUser}`;
    var requestData = {
      data: {
        idCustomer: +idCustomer,
      },
    };
    temeda.core.webApi
      .asyncPatch(uriPath, requestData)
      .then(() => {
        location.reload();
      })
      .catch(err => {
        console.error(err);
        temeda.core.spinner.hide();
        temeda.core.message.error("Error.", "An unknown error occurred while trying to switch companies.");
      });
  }

  return {
    credentials: null,
    user: null,
    loadCredentials: function() {
      loadCredentials(this);
    },
    // saveCredentials: function(expireHours) {
    //   saveCredentials(this, expireHours);
    // },
    logIn: function(usern, pwd, remember, logoff, redirect) {
      logIn(usern, pwd, remember, logoff, redirect);
    },
    logOut: function() {
      logOut(this);
    },
    authenticate: function() {
      return authenticate(this);
    },
    switchCompany: switchCompany,
    checkLogIn: checkLogIn,
    on: event.on,
    getAuthToken,
  };
})(jQuery);

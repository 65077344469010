var temeda = temeda || {}; // eslint-disable-line no-use-before-define
temeda.core = temeda.core || {};

temeda.core.message = (function() {
  "use strict";

  function moveCancelButtonToTheRight() {
    $(".sa-button-container button.cancel").appendTo($(".sa-confirm-button-container"));
  }

  function simple(title, message, confirmCallback) {
    sweetAlert(
      {
        title: title,
        text: message,
        showConfirmButton: true,
        confirmButtonText: "OK",
        closeOnConfirm: true,
      },
      confirmCallback,
    );
  }

  function info(title, message, confirmCallback) {
    sweetAlert(
      {
        title: title,
        text: message,
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "OK",
        closeOnConfirm: true,
      },
      confirmCallback,
    );
  }

  function success(title, message, confirmCallback) {
    sweetAlert(
      {
        title: title,
        text: message,
        type: "success",
        html: true,
        showConfirmButton: true,
        confirmButtonText: "OK",
        closeOnConfirm: true,
      },
      confirmCallback,
    );
  }

  function successAutoClose(title, message, confirmCallback) {
    sweetAlert(
      {
        title: title,
        text: message,
        type: "success",
        timer: 2000,
        showConfirmButton: false,
      },
      confirmCallback,
    );
  }

  function errorAutoClose(title, message, confirmCallback) {
    sweetAlert(
      {
        title: title,
        html: message,
        type: "error",
        timer: 2000,
        showConfirmButton: false,
      },
      confirmCallback,
    );
  }

  function errorUndone(message, confirmCallback) {
    sweetAlert(
      {
        title: "Undone!",
        text: message,
        imageUrl:
          "https://scontent.ford1-1.fna.fbcdn.net/v/t1.0-9/11227954_10155771800290103_6421561888131507637_n.jpg?oh=1c7f669a438e2b87100580ce1152159e&oe=58F4BDBC",
        imageWidth: 400,
        imageHeight: 200,
        animation: true,
        type: "error",
        timer: 2000,
        showConfirmButton: false,
      },
      confirmCallback,
    );
  }
  function successDone(message, confirmCallback) {
    sweetAlert(
      {
        title: "Done!",
        html: '<i class="fa fa-thumbs-up"></i> Great!',
        imageUrl:
          "https://scontent.ford1-1.fna.fbcdn.net/v/t1.0-9/11227954_10155771800290103_6421561888131507637_n.jpg?oh=1c7f669a438e2b87100580ce1152159e&oe=58F4BDBC",
        imageWidth: 400,
        imageHeight: 200,
        animation: true,
        type: "success",
        timer: 2000,
        showConfirmButton: false,
      },
      confirmCallback,
    );
  }

  function warning(title, message, confirmCallback) {
    sweetAlert(
      {
        title: title,
        text: message,
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "OK",
        closeOnConfirm: true,
      },
      confirmCallback,
    );
  }

  function error(title, message, confirmCallback) {
    sweetAlert(
      {
        title: title,
        text: message,
        type: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",
        closeOnConfirm: true,
      },
      confirmCallback,
    );
  }

  function confirm(title, message, confirmCallback) {
    var swalOptions = {};

    swalOptions.title = title;
    swalOptions.text = message;
    swalOptions.type = "info";
    swalOptions.showConfirmButton = true;
    swalOptions.confirmButtonText = "Yes";
    swalOptions.confirmButtonColor = "#337ab7";
    swalOptions.closeOnConfirm = true;
    swalOptions.showCancelButton = true;
    swalOptions.cancelButtonText = "No";
    swalOptions.closeOnCancel = true;

    sweetAlert(swalOptions, confirmCallback);
    moveCancelButtonToTheRight();
  }

  function confirmDelete(title, message, confirmCallback) {
    var swalOptions = {};

    swalOptions.title = title;
    swalOptions.text = message;
    swalOptions.type = "warning";
    swalOptions.showConfirmButton = true;
    swalOptions.confirmButtonText = "Delete";
    swalOptions.confirmButtonColor = "#d9534f";
    swalOptions.closeOnConfirm = true;
    swalOptions.showCancelButton = true;
    swalOptions.cancelButtonText = "Cancel";
    swalOptions.closeOnCancel = true;
    swalOptions.allowOutsideClick = false;

    sweetAlert(swalOptions, confirmCallback);
    moveCancelButtonToTheRight();
  }

  return {
    simple: simple,
    info: info,
    success: success,
    successAutoClose: successAutoClose,
    errorAutoClose: errorAutoClose,
    warning: warning,
    error: error,
    confirm: confirm,
    confirmDelete: confirmDelete,
    errorUndone: errorUndone,
    successDone: successDone,
  };
})();

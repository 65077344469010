var temeda = temeda || {}; // eslint-disable-line no-use-before-define
temeda.core = temeda.core || {};
temeda.core.utils = temeda.core.utils || {};
var AssetDashboardWindow; // global variable for assetDashboard window
var utilsWindowTabs = [];
const version = "2.86";

temeda.core.utils.getVersion = function() {
  return version; //version # that appears in url and in help about
};

temeda.core.utils.getVersionWebServer = function() {
  return version + " " + temeda.webServer;
};
temeda.core.utils.getMapModeBreakpoint = () => 10000;

temeda.core.utils.getWeatherKeys = function() {
  return {
    id: "LHUzdIDqmvg6hwMd8yqsN",
    secret: "JfN6QvW5grJzvOVXRbN5NSGd86TgqT9ev7PNv1SC",
  }; //keys api for weather app
};
temeda.core.utils.environmentClasses = function() {
  var $body = $("body");
  if (is.firefox()) {
    $body.addClass("firefox");
  }
  if (is.chrome()) {
    $body.addClass("chrome");
  }
  if (is.safari()) {
    $body.addClass("safari");
  }
  if (is.opera()) {
    $body.addClass("opera");
  }
  if (is.ie(9)) {
    $body.addClass("ie ie-9");
  }
  if (is.ie(10)) {
    $body.addClass("ie ie-10");
  }
  if (is.ie(11)) {
    $body.addClass("ie ie-11");
  }
  if (is.mac()) {
    $body.addClass("mac");
  }
  if (is.windows()) {
    $body.addClass("windows");
  }
  if (is.ios()) {
    $body.addClass("ios");
  }
  if (is.iphone()) {
    $body.addClass("iphone");
  }
  if (is.ipad()) {
    $body.addClass("ipad");
  }
  if (is.ipod()) {
    $body.addClass("ipod");
  }
  if (is.android()) {
    $body.addClass("android");
  }
  if (is.androidPhone()) {
    $body.addClass("android-phone");
  }
  if (is.androidTablet()) {
    $body.addClass("android-tablet");
  }
  if (is.blackberry()) {
    $body.addClass("blackberry");
  }
  if (is.windowsPhone()) {
    $body.addClass("windows-phone");
  }
  if (is.windowsTablet()) {
    $body.addClass("windows-tablet");
  }
  if (is.desktop()) {
    $body.addClass("desktop");
  }
  if (is.mobile()) {
    $body.addClass("mobile");
  }
  if (is.tablet()) {
    $body.addClass("tablet");
  }
};

//used to change country based on state/province selected
temeda.core.utils.canadaDistricts = ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "ON", "PE", "QC", "SK", "YT"];

temeda.core.utils.isMobileDevice = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (
      temeda.core.utils.isMobileDevice.Android() ||
      temeda.core.utils.isMobileDevice.BlackBerry() ||
      temeda.core.utils.isMobileDevice.iOS() ||
      temeda.core.utils.isMobileDevice.Opera() ||
      temeda.core.utils.isMobileDevice.Windows()
    );
  },
};

temeda.core.utils.getUrlParameters = function() {
  var parameters = {};
  var queryString = window.location.search.substring(1);

  if (queryString) {
    var queryKeyValues = queryString.split("&");

    queryKeyValues.forEach(function(keyValue) {
      var parts = keyValue.split("=");
      parameters[decodeURI(parts[0])] = parts[1] ? decodeURI(parts[1]) : null;
    });
  }

  return parameters;
};

temeda.core.utils.getUrlParameter = function(name) {
  var parameters = temeda.core.utils.getUrlParameters();
  return parameters[name];
};

temeda.core.utils.setCookie = function(name, value, expireHours) {
  if (expireHours) {
    var expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + expireHours * 3600000);
    document.cookie = name + "=" + value + "; expires=" + expireDate.toUTCString() + "; path=/";
  } else {
    document.cookie = name + "=" + value + "; path=/";
  }
};

temeda.core.utils.getCookie = function(name) {
  var re = new RegExp(name + "=([^;]+)");
  var value = re.exec(document.cookie);
  return value && value.length >= 2 ? value[1] : null;
};

temeda.core.utils.deleteCookie = function(name) {
  var expireDate = new Date(0);
  document.cookie = name + "=; expires=" + expireDate.toUTCString();
};

temeda.core.utils.findPropertyByNames = function(obj, propertyNames) {
  if (obj) {
    var propertyNameIndex;
    var propertyName;

    for (var p in obj) {
      if (obj.hasOwnProperty(p) && obj[p] !== undefined && obj[p] !== null) {
        var index = propertyNames.indexOf(p.toLowerCase());

        if (index >= 0 && (propertyNameIndex === undefined || index < propertyNameIndex)) {
          propertyNameIndex = index;
          propertyName = p;
        }
      }
    }

    if (propertyName !== undefined) {
      return obj[propertyName];
    }
  }

  return undefined;
};

temeda.core.utils.getUsersCurrentLocalDateTime = function(user) {
  return temeda.core.utils.convertFromUtcToUsersLocalDateTime(user, moment.utc());
};

temeda.core.utils.convertFromDateToUsersLocalDateTime = function(user, date) {
  if (user) {
    if (user.TZ_Moment_Name) {
      return moment.tz(moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS"), user.TZ_Moment_Name);
    } else if (user.TimeZone !== undefined && user.TimeZone !== null) {
      return moment(moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS"))
        .utcOffset(user.TimeZone)
        .add(moment().utcOffset() - user.TimeZone * 60, "m");
    } else {
      return moment(date);
    }
  } else {
    return moment(date);
  }
};

temeda.core.utils.convertFromUtcToUsersLocalDateTime = function(user, utc) {
  var m = moment.utc(utc);

  if (user) {
    if (user.TZ_Moment_Name) {
      m.tz(user.TZ_Moment_Name);
      return m;
    } else if (user.TimeZone !== undefined && user.TimeZone !== null) {
      m.utcOffset(user.TimeZone);
      return m;
    } else {
      m.utcOffset(moment(new Date()).utcOffset());
      return m;
    }
  } else {
    m.utcOffset(moment(new Date()).utcOffset());
    return m;
  }
};

temeda.core.utils.convertFromUsersLocalDateTimeToUtc = function(user, local) {
  if (user) {
    if (user.TZ_Moment_Name) {
      return moment.tz(moment(local).format("YYYY-MM-DDTHH:mm:ss.SSS"), user.TZ_Moment_Name).tz("UTC");
    } else if (user.TimeZone !== undefined && user.TimeZone !== null) {
      var m = moment(local);
      return m
        .add(m.utcOffset(), "m")
        .utc()
        .subtract(user.TimeZone, "h");
    } else {
      return moment(local).utc();
    }
  } else {
    return moment(local).utc();
  }
};

temeda.core.utils.convertToUsersDateFormat = function(user, date) {
  var d = moment(date);
  if (!d.isValid()) return date;
  if (user && user.Date_Format) {
    return d.format(user.Date_Format.toUpperCase());
  } else if (user && user.user_details.Date_Format) {
    return d.format(user.user_details.Date_Format.toUpperCase());
  } else {
    return d.format("MM/DD/YYYY");
  }
};

temeda.core.utils.convertToUsersTimeFormat = function(user, date) {
  var d = moment(date);

  if (user && user.Time_Format) {
    if (d.tz()) {
      return user.Time_Format === "12HR" ? d.format("h:mm A ") : d.format("HH:mm A ");
    } else {
      return user.Time_Format === "12HR" ? d.format("h:mm A ") : d.format("HH:mm A ");
    }
  } else {
    if (d.tz()) {
      return d.format("h:mm A ");
    } else {
      return d.format("h:mm A ");
    }
  }
};

temeda.core.utils.convertToUsersTimeFormatWithSeconds = function(user, date) {
  var d = moment(date);

  if (user && user.Time_Format) {
    if (d.tz()) {
      return user.Time_Format === "12HR" ? d.format("h:mm:ss A ") : d.format("HH:mm:ss A ");
    } else {
      return user.Time_Format === "12HR" ? d.format("h:mm:ss A ") : d.format("HH:mm:ss A ");
    }
  } else {
    if (d.tz()) {
      return d.format("h:mm:ss A ");
    } else {
      return d.format("h:mm:ss A ");
    }
  }
};

temeda.core.utils.convertToUsersDateTimeFormatWithSeconds = function(user, date) {
  return (
    temeda.core.utils.convertToUsersDateFormat(user, date) +
    " " +
    temeda.core.utils.convertToUsersTimeFormatWithSeconds(user, date)
  );
};

temeda.core.utils.convertToUsersDateTimeFormat = function(user, date) {
  return (
    temeda.core.utils.convertToUsersDateFormat(user, date) +
    " " +
    temeda.core.utils.convertToUsersTimeFormat(user, date)
  );
};

//use this to convert from UTC to local user time. ex: 04/21/2016 1:36 PM CDT
temeda.core.utils.convertToUsersLocalDateTimeFormatFromUTC = function(user, date) {
  return (
    temeda.core.utils.convertToUsersDateFormat(user, temeda.core.utils.convertFromUtcToUsersLocalDateTime(user, date)) +
    " " +
    temeda.core.utils.convertToUsersTimeFormat(user, temeda.core.utils.convertFromUtcToUsersLocalDateTime(user, date))
  );
};

temeda.core.utils.convertToProperCase = function(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

temeda.core.utils.boostrapModalHeader = function(title, iconPath, link) {
  var headerContent = $(
    '<div class="dialog-header">' +
      '<a  href="' +
      link +
      '" title="Help"><img class="icon-help" src="images/help-icon.png"></a>' +
      '<div class="modal-header-icon">' +
      '<img src="' +
      iconPath +
      '" alt="' +
      title +
      '"></div>' +
      '<div class="modal-header-title"><span><b>' +
      title +
      "</b></span></div></div>",
  );
  return headerContent;
};

temeda.core.utils.resizeAssetIcon = function(path, imageuse) {
  var image_path = path;
  if (!image_path) {
    return image_path;
  } else {
    var image_path_medium;
    if (imageuse === "-grid" && path.substr(0, 21) === "images/library/custom") {
      image_path_medium = "images/icon-camera.png";
    } else {
      image_path_medium = "/" + image_path.replace(".png", imageuse + ".png");
    }
    return image_path_medium;
  }
};

temeda.core.utils.draggable = function(modal, options) {
  var $modal = modal;
  $modal.click(".modal-body", function() {
    $modal.draggable({
      disabled: true,
    });
  });
  var xpos = $modal.width() - 400;
  var ypos = $modal.height() - 50;
  if (options) {
    $modal.mouseover(
      ".modal-header",
      function() {
        $modal.draggable({
          handle: options.handle !== undefined ? options.handle : ".modal-header",
          refreshPositions: options.refreshPositions !== undefined ? options.refreshPositions : true,
          cursor: options.cursor !== undefined ? options.cursor : "move",
          disabled: options.disabled !== undefined ? options.disabled : false,
          containment: [-xpos, -50, xpos, ypos],
        });
      },
      { passive: true },
    );
  } else {
    $modal.mouseover(".modal-header", function() {
      $modal.draggable({
        handle: ".modal-header",
        refreshPositions: true,
        cursor: "move",
        disabled: false,
        containment: [-xpos, -50, xpos, ypos],
      });
    }),
      { passive: true };
  }
  //maybe resizeable need to figure out more
  //$modal.resizable({
  //  handle: 'n, e, s, w',
  //  helper: 'resizable-helper',
  //  alsoResize: '.modal-body',
  //  animate: true,
  //  animateEasing: 'easeOutBounce',
  //  ghost: true
  //});
};

temeda.core.utils.formatNumberWithCommas = function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

temeda.core.utils.convertMetersToUsersDistance = function(numbers, des) {
  var conversionPercent = 0.000621371192; //default to miles
  if (temeda.core.security.user.Distance_UOM && temeda.core.security.user.Distance_UOM === "km") {
    conversionPercent = 0.001;
  }
  if (des) {
    return parseFloat((numbers * conversionPercent).toFixed(des));
  } else {
    return Math.round(numbers * conversionPercent);
  }
};

temeda.core.utils.convertMilesToUsersDistance = function(numbers) {
  var conversionPercent = 0.000621371192; //default to miles
  if (temeda.core.security.user.Distance_UOM && temeda.core.security.user.Distance_UOM === "km") {
    conversionPercent = 0.001;
  }
  return Math.round(numbers / conversionPercent);
};

// return unit of measure for some  fields
temeda.core.utils.returnUOM = function addUOMtoHeader(deviceFieldName, user) {
  //deviceFieldName is from the DCD.CustomerFields table
  switch (deviceFieldName) {
    case "Asset_Odometer":
    case "Engine_Odometer":
    case "FindNearestAssetDistance":
      return user.Distance_UOM;
    case "Last_GPS_Speed":
      return user.Speed_UOM;
    case "Last_Total_Engine_Idle_Fuel":
    case "engineTotalFuelUsed":
      return user.Volume_UOM;
    case "Last_Battery_Temp":
    case "Last_Temp_Sensor1":
    case "engineCoolantTemp":
    case "sanyHydraulicOilTempHCU2":
    case "engineECUTemperature":
    case "HydraulicTemp":
    case "TransOilTemp":
    case "DEFTankTemp":
    case "CompressorTemp":
      return user.Temperature_UOM;
    case "FuelEfficiency":
      return user.Fuel_Rate_UOM;
    case "Last_Altitude":
      return user.Distance_UOM === "km" ? "m" : "ft"; //if user has kilometers altitude will be meters otherwise feet
    case "engineOilPressure":
    case "sanyFrontPumpMainPressureHCU2":
    case "sanyRearPumpMainPressureHCU2":
    case "AirPressure":
      return user.Pressure_UOM;
    case "engineFuelRate":
      return user.Volume_UOM.toLowerCase() + "/hr";
    case "Last_Battery_Voltage":
      return "v";
    default:
      return "";
  }
};

temeda.core.utils.generateGuid = function generateGuid() {
  function S4() {
    return parseInt((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
};

temeda.core.utils.loadDetail = function(assetid, route) {
  // show asset dashboard for passed in assetid
  if (!assetid) {
    //no assetid was passed - get the first one
    assetid = temeda.core.datasetMonitor.get("assets")[0].idAsset;
  }
  var path = route;
  if (!route) {
    route = "";
  } else {
    route = "#/" + path;
  }
  var src = "/assetDashboard/assetDashboard.html?v=" + temeda.core.utils.getVersion() + "&assetid=" + assetid + route;
  AssetDashboardWindow = temeda.core.utils.PopupCenter(src, "Asset Dashboard");
  if (AssetDashboardWindow.focus) {
    //to make assetDashboard come to front
    AssetDashboardWindow.focus(); //verify that focus is available before calling it
  }
  utilsWindowTabs.push(AssetDashboardWindow);
};

/**
 * Debounces whatever event you want.
 * @param  {function} func      The function to trigger.
 * @param  {number}   wait      The amount of time of no invokes before the function is triggered.
 * @param  {boolean}  immediate Triggers the function on the first invoke.
 * @return {function}           The function to call to invoke the debounce.
 */
temeda.core.utils.debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this;
    var args = arguments;

    var later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

temeda.core.utils.isEmail = function(email) {
  //var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; //allow apostrphes
  return regex.test(email);
};

temeda.core.utils.ordinalNumber = function(number) {
  var order = {
    0: "First",
    1: "Second",
    2: "Third",
    3: "Fourth",
    4: "Fifth",
    5: "Sixth",
    6: "Seventh",
    7: "Eighth",
    8: "Ninth",
    9: "Tenth",
    10: "Eleventh",
    11: "Twelfth",
    12: "Thirteenth",
    13: "Fourteenth",
    14: "Fifteenth",
    15: "Sixteenth",
    16: "Seventeenth",
    17: "Eighteenth",
    18: "Nineteenth",
    19: "Twentieth",
  };
  return order[number];
};

temeda.core.utils.calculateSpeedTo = function(uom, value) {
  var u = uom.toLowerCase();
  var v = parseFloat(value);
  if (u === "mph") {
    v = v * 0.44704;
  } else if (u === "km/h") {
    v = v * 0.277777778;
  } else if (u === "kn") {
    v = v * 0.514444;
  }

  return v;
};
// Calculate when get m/s --> uom
temeda.core.utils.calculateSpeedFrom = function(uom, value) {
  var u = uom.toLowerCase();
  var v = parseFloat(value);
  if (u === "mph") {
    v = v * 2.23693629;
  } else if (u === "km/h") {
    v = v * 3.6;
  } else if (u === "kn") {
    v = v * 1.94384449;
  }

  return Math.round(v);
};

//Calculate temp from users uom to celsius
temeda.core.utils.calculateTemperatureFrom = function(uom, value) {
  var u = uom.toLowerCase();
  var t = parseFloat(value);
  if (u.search("f") > -1) {
    //uom can include degree symbol so search for f
    t = t * (9 / 5) + 32;
  }
  return Math.round(t);
};

//Calculate temp from celsius to users uom
temeda.core.utils.calculateTemperatureTo = function(uom, value) {
  var u = uom.toLowerCase();
  var t = parseFloat(value);
  if (u.search("f") > -1) {
    //uom can include degree symbol so search for f
    t = (t - 32) * (5 / 9);
  }
  return Math.round(t);
};

// //Calculate pressure from users uom to kg/cm2
// temeda.core.utils.calculatePressureFrom = function(uom, value) {
//   var u = uom.toLowerCase();
//   var t = parseFloat(value);
//   if (u === "psi") {
//     t = t / 14.223343334285;
//   } else if (u === "kpa") {
//     t = t / 98.0665;
//   }
//   return t;
// };

// //Calculate pressure from users uom to kg/cm2
// temeda.core.utils.calculatePressureTo = function(uom, value) {
//   var u = uom.toLowerCase();
//   var t = parseFloat(value);
//   if (u === "psi") {
//     t = t * 14.223343334285;
//   } else if (u === "kpa") {
//     t = t * 98.0665;
//   }
//   return t;
// };

//Calculate pressure from users uom to kg/cm2
temeda.core.utils.convertPressure = function(fromUOM, toUOM, value) {
  var fu = fromUOM.toLowerCase();
  var tu = toUOM.toLowerCase();
  var t = parseFloat(value);
  //convert to kg/cm2
  if (fu === "psi") {
    t = t / 14.223343334285;
  } else if (fu === "kpa") {
    t = t / 98.0665;
  }

  //now convert to toUOM
  if (tu === "psi") {
    t = t * 14.223343334285;
  } else if (tu === "kpa") {
    t = t * 98.0665;
  }

  return t;
};

// Calculate seconds to hours
temeda.core.utils.calculateSecToHours = function(seconds) {
  var hours;
  hours = seconds / 3600;
  return hours;
};
// Calculate minutes to hours
temeda.core.utils.calculateMinToHours = function(minutes) {
  var hours;
  hours = minutes / 60;
  return hours;
};
// Calculate minutes to hours
temeda.core.utils.calculateHoursToMin = function(hours) {
  var minutes;
  minutes = hours * 60;
  return minutes;
};

function generateGeoJSONCircle(lat, lng, radius, numSides) {
  var points = [],
    degreeStep = 360 / numSides;

  for (var i = 0; i < numSides; i++) {
    var gpos = google.maps.geometry.spherical.computeOffset(
      new google.maps.LatLng({
        lat: lat,
        lng: lng,
      }),
      radius,
      degreeStep * i,
    );
    points.push([gpos.lng(), gpos.lat()]);
  }

  // Duplicate the last point to close the geojson ring
  points.push(points[0]);

  return points;
}

function parsePolygon(Landmark_Location_Text) {
  var output = [];
  var txt = Landmark_Location_Text.split("(");
  txt = txt[txt.length - 1].split(")");
  var text_arr_2 = txt[0].split(", ");
  var latlng;
  for (
    var i = 0;
    i < text_arr_2.length;
    i++ //the last point returned by getlandmark is the duplicate of the first point
  ) {
    latlng = text_arr_2[i].split(" ");
    output[i] = [parseFloat(latlng[0]), parseFloat(latlng[1])];
  }

  return output;
}

temeda.core.utils.createGeoJson = function(selectedLocations) {
  // var allLocations = temeda.core.datasetMonitor.get(geotype);
  // var selectedLocations = _.filter(allLocations, function(o) {
  //   if (arr.indexOf(o.idLandmark) !== -1) {
  //     return o;
  //   }
  // });
  var polygonJson = _.mapValues(selectedLocations, function(landmark) {
    var coordinates;
    var labelcoords;
    if (landmark.Landmark_Type === "Circle") {
      coordinates = generateGeoJSONCircle(landmark.Latitude, landmark.Longitude, landmark.Landmark_Radius, 180);
      labelcoords = new google.maps.LatLng({
        lat: landmark.Latitude,
        lng: landmark.Longitude,
      });
    } else if (landmark.Landmark_Type === "Polygon") {
      coordinates = parsePolygon(landmark.Landmark_Location_Text);
      var bounds = new google.maps.LatLngBounds();
      coordinates.forEach(function(points) {
        bounds.extend(new google.maps.LatLng(points[1], points[0]));
      });
      labelcoords = bounds.getCenter();
    }

    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [coordinates],
        labelcoords: labelcoords,
      },
      properties: {
        color: "yellow",
        id: landmark.idLandmark,
        Name: landmark.Landmark_Name,
        AllowAdminToEdit: landmark.AllowAdminToEdit,
      },
    };
  });
  var array = $.map(polygonJson, function(value) {
    return [value];
  });
  var geojson = {
    type: "FeatureCollection",
    features: array,
  };
  return geojson;
};

temeda.core.utils.createSingleGeoJson = function(data) {
  var landmark = data;
  var coordinates;
  var labelcoords;
  if (landmark.Landmark_Type === "Circle") {
    coordinates = generateGeoJSONCircle(landmark.Latitude, landmark.Longitude, landmark.Landmark_Radius, 180);
    labelcoords = new google.maps.LatLng({
      lat: landmark.Latitude,
      lng: landmark.Longitude,
    });
  } else if (landmark.Landmark_Type === "Polygon") {
    coordinates = parsePolygon(landmark.Landmark_Location_Text);
    var bounds = new google.maps.LatLngBounds();
    coordinates.forEach(function(points) {
      bounds.extend(new google.maps.LatLng(points[1], points[0]));
    });
    labelcoords = bounds.getCenter();
  }
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [coordinates],
      labelcoords: labelcoords,
    },
    properties: {
      color: "yellow",
      id: landmark.idLandmark,
      Name: landmark.Landmark_Name,
      AllowAdminToEdit: landmark.AllowAdminToEdit,
    },
  };
};

temeda.core.utils.processPoints = function(geometry, callback, thisArg) {
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function(g) {
      temeda.core.utils.processPoints(g, callback, thisArg);
    });
  }
};

temeda.core.utils.inBounds = (point, bounds) => {
  //return boolean if point is within bounds
  //point {
  //   lat: string,
  //   long: string
  // },
  // bounds {
  //   westLong: number,
  //   eastLong: number,
  //   northLat: number,
  //   southLat: number
  // }
  var eastBound = point.long <= bounds.eastLong;
  var westBound = point.long > bounds.westLong;
  var inLong;

  if (bounds.eastLong < bounds.westLong) {
    inLong = eastBound || westBound;
  } else {
    inLong = eastBound && westBound;
  }
  var inLat = point.lat >= bounds.southLat && point.lat < bounds.northLat;
  return inLat && inLong;
};

const getUserPassword = () => {
  var imper_user = temeda.core.security.credentials.impersonate;
  if (imper_user) {
    return window.atob(temeda.core.security.credentials.password) + ":" + imper_user;
  } else {
    return window.atob(temeda.core.security.credentials.password);
  }
};

temeda.core.utils.createReport = function(reportType, idAsset, assetLabel, user, username, password) {
  //Report Definitions
  let thisUser = user || temeda.core.security.user;
  let thisUsername = username || window.atob(temeda.core.security.credentials.username);
  let thisPassword = "";
  if (!password) {
    thisPassword = getUserPassword();
  } else {
    thisPassword = password;
  }
  var currentDate = moment(new Date());
  var startDate = moment(currentDate).format(thisUser.Date_Format.toUpperCase()) + "+00:00";
  var endDate = moment(currentDate).format(thisUser.Date_Format.toUpperCase()) + "+23:59";
  var startDateFormat = moment(currentDate).format(thisUser.Date_Format.toUpperCase()) + " 12:00 am ";
  var endDateFormat = moment(currentDate).format(thisUser.Date_Format.toUpperCase()) + " 11:59 pm ";
  var rdURL = reportType;
  if (reportType) {
    var formData = {
      //'rdReport': asset.reportSelected.reportName + urlExtension,
      rdReport: rdURL,
      ihVelocity: thisUser.Speed_UOM,
      ihDistance: thisUser.Distance_UOM,
      ihFuelEconomy: thisUser.Fuel_Rate_UOM,
      ihTemperature: thisUser.Temperature_UOM,
      ihVolume: thisUser.Volume_UOM,
      ihSelectedAsset: encodeURIComponent(assetLabel),
      islAssets: idAsset,
      irbAssetSelOptions: "Asset",
      islDateRange: "Today",
      inpStartDate: startDate,
      inpEndDate: endDate,
      inpStartDateDisp: startDateFormat,
      inpEndDateDisp: endDateFormat,
      username: thisUsername,
      password: thisPassword,
      token: "Basic " + window.btoa(thisUsername + ":" + thisPassword),
      idUser: thisUser.idUser,
      brandingImage: window.location.hostname + "/images/" + thisUser.Branding.Logo,
      ids: idAsset,
      dateselect: true,
      rowCount: 50,
    };

    formData.reportingServer = temeda.core.config.reportingserver;
    runReport(formData);
  }
};

function runReport(formData) {
  if (formData.brandingImage && temeda.core.config.azureImagesStorageAccountUrlBase) {
    var azureImagesStorageAccountUrlBase = temeda.core.config.azureImagesStorageAccountUrlBase;
    formData.brandingImage = fixBrandingImage(formData.brandingImage, azureImagesStorageAccountUrlBase);
  }
  var form = document.createElement("form");
  form.target = "_blank";
  form.setAttribute("method", "post");
  form.setAttribute("action", temeda.core.config.reportingserver);
  for (var i in formData) {
    if (formData.hasOwnProperty(i)) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = i;
      input.value = formData[i];
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

function fixBrandingImage(brandingImage, azureImagesStorageAccountUrlBase) {
  var splitImageString = brandingImage.split("/");
  splitImageString[0] = azureImagesStorageAccountUrlBase;
  return splitImageString.join("/");
}

temeda.core.utils.capitalizeFirstLetter = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Calculates the natural aspect ratio of the image in question,
 * to add the appropriate class to the parent element.
 * @param srcImageContainer - The image's container element
 * @param srcImage - The image itself
 */
temeda.core.utils.calculateImgAspectRatio = function(srcImageContainer, srcImage) {
  /**
   * Euclidean Algorithm for finding the Greatest Common Divisor of two numbers
   * Note: a and b must be positive integers, calculates GCD
   * @param a
   * @param b
   * @returns {*}
   * @private
   */
  var _greatestCommonDivisor = function(a, b) {
    setTimeout(function() {
      if (Number(a) === 0) {
        return b;
      }
      while (b > 0) {
        if (a > b) {
          a = a - b;
        } else {
          b = b - a;
        }
      }
    }, 500);
    return a;
  };

  /**
   * Ratio is to get the greatest common divisor, (gcd), and divide each component by the gcd
   * Then returns an object with the typical colon-separated ratio value and independent width and height values
   * @param x
   * @param y
   * @returns {{ratio: string, width: number, height: number}}
   * @private
   */
  var _ratio = function(x, y) {
    var c = _greatestCommonDivisor(x, y);
    return {
      ratio: x / c + ":" + y / c,
      width: x / c,
      height: y / c,
    };
  };

  // Get the parent container
  var imgContainer = srcImageContainer;
  // Get the image reference
  var img = srcImage;
  // Create a temp image, cause jQuery sucks at using the existing `img` variable for some reason
  var tmpImg = new Image();
  // Set the source of the temporary image to the same as `img`
  tmpImg.src = img.attr("src");
  // Magic happens here when we use _ratio() to calculate the ratio
  var imgRatio = _ratio(tmpImg.naturalWidth, tmpImg.naturalHeight);

  // Set the class appropriately depending on the values returned from _ratio()
  if (imgRatio.width > imgRatio.height) {
    // If the image is wider than it is taller
    if (imgContainer.hasClass("tall-img")) {
      imgContainer.addClass("wide-img");
      imgContainer.removeClass("tall-img");
    } else {
      imgContainer.addClass("wide-img");
    }
  } else {
    if (imgContainer.hasClass("wide-img")) {
      imgContainer.addClass("tall-img");
      imgContainer.removeClass("wide-img");
    } else {
      imgContainer.addClass("tall-img");
    }
  }
};

temeda.core.utils.closeUtilsTabs = function() {
  _.forEach(utilsWindowTabs, function(tab) {
    tab.close();
    return tab;
  });
};

temeda.core.utils.setLogoutAtMidnightTimer = () => {
  //set timeout to log user out at midnight
  var midnightTonight = moment()
    .add(1, "days")
    .startOf("day");
  var msUntilMidnight = midnightTonight.diff(moment());
  setTimeout(function() {
    temeda.core.security.logOut();
  }, msUntilMidnight);
}; // setLogoutAtMidnightTimer()

temeda.core.utils.getRestrictedAdminCommands = () => {
  //for restricted admin check if the user has restricted admin commands access
  let instant = {
    isRestrictedAdmin: temeda.core.security.user.UserRole.idUserRole === 11,
    locationsEnabled: false,
    assetsEnabled: false,
    groupsEnabled: false,
  };
  if (instant.isRestrictedAdmin) {
    const sections = temeda.core.security.user.RestrictedAdminAccess
      ? temeda.core.security.user.RestrictedAdminAccess.split(",")
      : [];
    instant.assetsEnabled = _.indexOf(sections, "assets") !== -1;
    instant.locationsEnabled = _.indexOf(sections, "locations") !== -1;
    instant.groupsEnabled = _.indexOf(sections, "groups") !== -1;
  }
  return instant;
};

temeda.core.utils.reducedAsset = idAsset => {
  let assetsReduced = null;
  let assetItem = null;
  if (idAsset) {
    assetsReduced = temeda.core.datasetMonitor.getSingle("assets", idAsset);
    assetItem = _.pick(assetsReduced, [
      "Asset_Label",
      "idAsset",
      "Asset_Icon_Full_Path",
      "Alert_Count",
      "MaintDueCount",
      "MaintOverdueCount",
      "CurrentDriver",
      "Icon_Color",
      "idDevice",
    ]);
    //Get stuff from device and put it in root to make the object less complex
    assetItem.Last_Event = assetsReduced.Device.Last_Event;
    assetItem.Serial_Number_Displayed = assetsReduced.Device.Serial_Number_Displayed;
    assetItem.Last_Location_DateTime_Local = assetsReduced.Device.Last_Location_DateTime_Local;
    assetItem.In_Motion = assetsReduced.Device.In_Motion;
    assetItem.Last_Heading = assetsReduced.Device.Last_Heading;
    assetItem.Last_Heading_Cardinal = assetsReduced.Device.Last_Heading_Cardinal;
    var fieldValue = "";
    if (assetsReduced["Device"]["Last_Landmark_Name"] && assetsReduced["Device"]["Last_Nearby_Address"]) {
      fieldValue = `${assetsReduced["Device"]["Last_Landmark_Name"]} / ${assetsReduced["Device"]["Last_Nearby_Address"]}`;
    } else if (assetsReduced["Device"]["Last_Landmark_Name"]) {
      fieldValue = assetsReduced["Device"]["Last_Landmark_Name"];
    } else if (assetsReduced["Device"]["Last_Nearby_Address"]) {
      fieldValue = assetsReduced["Device"]["Last_Nearby_Address"];
    } else if (assetsReduced["Device"]["Latitude"] && assetsReduced["Device"]["Longitude"]) {
      fieldValue = `${assetsReduced["Device"]["Latitude"]} , ${assetsReduced["Device"]["Longitude"]}`;
    } else {
      fieldValue = null;
    }

    assetItem.Last_Nearby_Address = fieldValue;
  }
  return assetItem;
};

temeda.core.utils.reducedAssetsList = () => {
  let assetsReduced = null;
  assetsReduced = temeda.core.datasetMonitor.get("assets");
  const allReducedAssets = _.map(assetsReduced, asset => {
    return {
      Asset_Label: asset.Asset_Label,
      idAsset: asset.idAsset,
      Asset_Description: asset.Asset_Description,
      Year: asset.Year,
      Make: asset.Make,
      Model: asset.Model,
      Serial_Number_Displayed: asset.Device ? asset.Device.Serial_Number_Displayed : asset.Serial_Number_Displayed,
      Asset_Icon_Full_Path: asset.Asset_Icon_Full_Path,
      Asset_idImageCustom: asset.Asset_idImageCustom,
    };
  });

  return allReducedAssets;
};

// temeda.core.utils.isEnterpriseView = () => {
//   return temeda.core.security.userRole.customLandingPage();
// };

temeda.core.utils.b64EncodeUnicode = str => {
  //see https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode("0x" + p1);
    }),
  );
};

temeda.core.utils.b64DecodeUnicode = str => {
  //see https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
};

temeda.core.utils.PopupCenter = (url, title) => {
  //https://stackoverflow.com/a/48438211/1338911
  var userAgent = navigator.userAgent,
    mobile = function() {
      return (
        /\b(iPhone|iP[ao]d)/.test(userAgent) ||
        /\b(iP[ao]d)/.test(userAgent) ||
        /Android/i.test(userAgent) ||
        /Mobile/i.test(userAgent)
      );
    },
    screenX = typeof window.screenX !== "undefined" ? window.screenX : window.screenLeft,
    screenY = typeof window.screenY !== "undefined" ? window.screenY : window.screenTop,
    outerWidth = typeof window.outerWidth !== "undefined" ? window.outerWidth : document.documentElement.clientWidth,
    outerHeight =
      typeof window.outerHeight !== "undefined" ? window.outerHeight : document.documentElement.clientHeight - 22,
    targetWidth = mobile() ? null : screen.width * 0.8,
    targetHeight = mobile() ? null : screen.height * 0.8,
    V = screenX < 0 ? window.screen.width + screenX : screenX,
    left = parseInt(V + (outerWidth - targetWidth) / 2, 10),
    right = parseInt(screenY + (outerHeight - targetHeight) / 2.5, 10),
    features = [];
  features.push(`width=${targetWidth}`);
  features.push(`height=${targetHeight}`);
  features.push("location=0");
  features.push("status=1");
  features.push("toolbar=1");
  features.push("left=" + left);
  features.push("top=" + right);
  features.push("scrollbars=1");
  features.push("resizable=1");
  var newWindow = window.open(url, title, features.join(","));

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
};

temeda.core.utils.sortCaseInsensitive = sortCase => {
  if (Array.isArray(sortCase)) {
    sortCase.sort(function(a, b) {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    });
  }
  return sortCase;
};

var temeda = temeda || {}; // eslint-disable-line no-use-before-define
temeda.core = temeda.core || {};

temeda.core.spinner = (function($) {
  "use strict";

  var spinnerHtml = '<div class="spinner-overlay" tabindex="-1"><div class="spinner"></div></div>';

  function show() {
    $(spinnerHtml).appendTo("body");
  }

  function hide() {
    $(".spinner-overlay").remove();
  }

  function showForElementId(id) {
    $(
      `<div class="spinner-overlay-inner spinner-overlay-${id}" tabindex="-1"><div class="spinner"></div></div>`
    ).prependTo(`#${id}`);
  }

  function hideForElementId(id) {
    $(`.spinner-overlay-${id}`).remove();
  }

  return {
    show: show,
    hide: hide,
    showForElementId: showForElementId,
    hideForElementId: hideForElementId
  };
})(jQuery);

var temeda = temeda || {}; // eslint-disable-line no-use-before-define
temeda.core = temeda.core || {};
temeda.core.security = temeda.core.security || {};

temeda.core.security.userRole = (function($) {
  "use strict";
  temeda.enum = {
    RoleType: {
      TemedaAdministrator: 0, //superuser
      Administrator: 1, //user admin
      User: 2, //user non-admin
      DealerAdministrator: 3, //temeda dealer admin
      DealerSupport: 4, //temeda dealer support
      DealerRep: 5, //temeda dealer rep
      Anonymous: 6,
      Vendor: 7,
      Sales: 8, //user non-admin
      Service: 9, //user non-admin
      Technician: 10, //user non-admin
      RestrictedAdministrator: 11, // administrator with restricted access
    },
    CustomerType: {
      Account: 1, //parent (normal account)
      Dealership: 2, //dealership (child account) (hierarchyLevel)
      Customer: 3, //customer (child account)
    },
  };

  let addDeleteAssetEditSerialnumber = false; // customer config can override this to allow lower level admins to add assets and edit serial numbers on existing assets
  var permissions = {
    addAssets: false,
    editAssets: false,
    deleteAssets: false,
    isAdministrator: false,
  };

  let homepage = "opening.html?v=" + temeda.core.utils.getVersion();
  // look in to the Customer configs to find the enterprise user from .CustomerConfigurations array of ConfigSettings
  const customLandingPage = user => {
    let landingPage = null;
    user.CustomerConfigurations.forEach(configs => {
      if (configs.ConfigSettings) {
        const splitCommas = configs.ConfigSettings.split(",");
        if (splitCommas) {
          splitCommas.forEach(config => {
            const splitString = config.split("=");
            if (splitString.length) {
              let i = 0;
              for (i; i < splitString.length; i++) {
                if (splitString[i] === "opening") {
                  landingPage = splitString[i + 1];
                  return;
                }
              }
            }
          });
        }
      }
    });
    return landingPage;
  };

  function setup_homepage(user) {
    switch (user.Role_Id) {
      case temeda.enum.RoleType.TemedaAdministrator:
      case temeda.enum.RoleType.DealerAdministrator: {
        homepage = "./management?v=" + temeda.core.utils.getVersion();
        break;
      }
      default: {
        if (is.mobile()) {
          homepage = "mobile/index.html?v=" + temeda.core.utils.getVersion() + "/#/";
        } else {
          homepage = "opening.html?v=" + temeda.core.utils.getVersion();
        }

        break;
      }
    }

    //check for enterprise user
    const customPage = customLandingPage(user);
    if (customPage) {
      homepage = `${customPage}?v=${temeda.core.utils.getVersion()}`;
      temeda.core.utils.setCookie("enterprise", true); //session variable to track if user is logged in
    } else {
      temeda.core.utils.deleteCookie("enterprise"); //session variable to track if user is logged in
    }

    // console.log("user objects", user);
    return homepage;
  }

  function return_homepage() {
    return homepage;
  }

  function setup_impersonateThisUser(idUserRole) {
    var impersonateThisUser = false;
    switch (idUserRole) {
      case temeda.enum.RoleType.TemedaAdministrator:
      case temeda.enum.RoleType.DealerAdministrator:
      case temeda.enum.RoleType.DealerRep:
      case temeda.enum.RoleType.DealerSupport: {
        impersonateThisUser = false;
        break;
      }
      default: {
        impersonateThisUser = true;
        break;
      }
    }
    return impersonateThisUser;
  }

  function setupPermissions() {
    if (
      temeda.core.security.user.idCustomerType === temeda.enum.CustomerType.Account ||
      addDeleteAssetEditSerialnumber === true
    ) {
      permissions.addAssets = true;
      permissions.editAssets = true;
      permissions.deleteAssets = true;
    }

    //set permissions by user role
    switch (temeda.core.security.user.UserRole.idUserRole) {
      case temeda.enum.RoleType.TemedaAdministrator:
      case temeda.enum.RoleType.DealerAdministrator:
      case temeda.enum.RoleType.Administrator: {
        permissions.isAdministrator = true;
        break;
      }
      case temeda.enum.RoleType.User:
      case temeda.enum.RoleType.Sales:
      case temeda.enum.RoleType.Service:
      case temeda.enum.RoleType.Technician:
      case temeda.enum.RoleType.RestrictedAdministrator: {
        permissions.isAdministrator = false;
        break;
      }
      default: {
        permissions.isAdministrator = false;
        break;
      }
    }
    return permissions;
  }

  function getPermissions() {
    return permissions;
  }

  // function createMenu(menus) {
  //   // main li div
  //   //
  //   var menuLiElement = document.createElement("li");
  //   var menuAElement = document.createElement("a");
  //   var menuSVGElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  //   var menuUseElement = document.createElementNS("http://www.w3.org/2000/svg", "use");
  //   var menuSpanElement = document.createElement("span");
  //   var menuArrowElement = document.createElement("span");

  //   const { maintitle, submenu } = menus;

  //   //li Element
  //   const menuKey = maintitle.toLowerCase().replace(/\s/g, "");
  //   menuLiElement.classList.add("dropdown");
  //   menuLiElement.setAttribute("data-menu-key", menuKey);
  //   menuLiElement.setAttribute("data-qa", menuKey);

  //   //add listenerto li

  //   //add class open to menu
  //   // menuLiElement.addEventListener("mouseenter", e => {
  //   //   e.currentTarget.classList.add("open");
  //   // });

  //   //remove class open from menu
  //   menuLiElement.addEventListener("mouseleave", e => {
  //     e.currentTarget.classList.remove("open");
  //   });

  //   //a Element
  //   menuAElement.classList.add("-toggle");
  //   menuAElement.setAttribute("role", "button");
  //   menuAElement.setAttribute("ng-click", "SideBar = false");
  //   menuAElement.setAttribute("href", "#");
  //   menuAElement.setAttribute("aria-haspopup", true);
  //   menuAElement.setAttribute("aria-expanded", false);

  //   //svg Element

  //   menuSVGElement.setAttribute("role", "img");
  //   //menuSVGElement.classList.add("icon", `icon--${menuKey}`, "menu-icon");
  //   menuSVGElement.setAttribute("class", `icon icon--${menuKey} menu-icon`);

  //   //use Element
  //   menuUseElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#icon_chart_icon");
  //   //span Element
  //   menuSpanElement.classList.add("menu-label");
  //   menuSpanElement.setAttribute("ng-class", "[!SideBar?'':'show-sidebar-menu-label']");
  //   //append use to svg
  //   menuSVGElement.appendChild(menuUseElement);
  //   //arrow element
  //   menuArrowElement.classList.add("arrow");
  //   menuArrowElement.setAttribute("ng-class", "[!SideBar?'':'show-sidebar-menu-label']");
  //   menuArrowElement.appendChild(document.createElement("span"));
  //   menuArrowElement.appendChild(document.createElement("span"));

  //   //append svg to a element
  //   menuSpanElement.innerText = maintitle;
  //   menuAElement.appendChild(menuSVGElement);
  //   menuAElement.appendChild(menuSpanElement);
  //   menuAElement.appendChild(menuArrowElement);

  //   //     <li class="dropdown"
  //   //     data-menu-key="help"
  //   //     data-qa="10800">
  //   //   <a href="#"
  //   //      class="dropdown-toggle"
  //   //      data-toggle="dropdown"
  //   //      role="button"
  //   //      aria-haspopup="true"
  //   //      aria-expanded="false">
  //   //     <svg role="img"
  //   //          class="icon icon--help menu-icon">
  //   //       <use xlink:href="#icon_information_filled"></use>
  //   //     </svg>
  //   //     <span class="menu-label">Help</span>
  //   //   </a>

  //   const submenuUl = document.createElement("ul");
  //   submenuUl.classList.add("dropdown-menu");

  //   submenu.forEach(sub => {
  //     //submenu item
  //     const submenuLiElement = document.createElement("li");
  //     const submenuKey = sub.subtitle.toLowerCase().replace(/\s/g, "");
  //     submenuLiElement.classList.add("sub-menu-item");
  //     submenuLiElement.setAttribute("data-sub-menu-key", submenuKey);
  //     submenuLiElement.setAttribute("data-qa", submenuKey);
  //     submenuLiElement.setAttribute("data-url", sub.sublink);

  //     //submenu icon
  //     const submenuDivElement = document.createElement("div");
  //     submenuDivElement.classList.add("sub-menu-icon");
  //     const submenuSVGElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  //     const submenuUseElement = document.createElementNS("http://www.w3.org/2000/svg", "use");
  //     submenuSVGElement.setAttribute("role", "img");
  //     // submenuSVGElement.classList.add("icon", `icon--${submenuKey}`, "menu-icon");
  //     submenuSVGElement.setAttribute("class", `icon icon--${submenuKey} menu-icon`);
  //     submenuUseElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#icon_asset_dashboard");
  //     //submenu label
  //     const submenuLabelDivElement = document.createElement("div");
  //     submenuLabelDivElement.classList.add("sub-menu-label");

  //     //appending elemets
  //     submenuLabelDivElement.innerHTML = sub.subtitle;
  //     submenuSVGElement.appendChild(submenuUseElement);
  //     submenuDivElement.appendChild(submenuSVGElement);
  //     //added
  //     submenuLiElement.appendChild(submenuDivElement);
  //     submenuLiElement.appendChild(submenuLabelDivElement);
  //     //add class open to menu
  //     submenuLiElement.addEventListener("click", e => {
  //       e.preventDefault();
  //       e.stopPropagation();
  //       window.open(sub.sublink, "_blank");
  //     });
  //     submenuUl.appendChild(submenuLiElement);
  //   });

  //   //   <ul class="dropdown-menu">
  //   //     <li class="sub-menu-item"
  //   //         data-sub-menu-key="helpUserGuide"
  //   //         data-qa="10801">
  //   //       <div class="sub-menu-icon">
  //   //         <svg role="img"
  //   //              class="icon icon--help__user-guide">
  //   //           <use xlink:href="#icon_book"></use>
  //   //         </svg>
  //   //       </div>
  //   //       <div class="sub-menu-label">Help Center</div>
  //   //     </li>
  //   //   </ul>
  //   // </li>
  //   menuLiElement.appendChild(menuAElement);
  //   menuLiElement.appendChild(submenuUl);

  //   return menuLiElement;
  // }

  function init() {
    var sections = [];
    if (temeda.core.security.user.UserRole.idUserRole === temeda.enum.RoleType.RestrictedAdministrator) {
      //restricted admin
      // These are the menu options to show or hide for restricted admins
      const adminMenuSections = temeda.core.dropdownUtils.adminOptions().map(function(item) {
        return item.id;
      });

      // remove user menu and custom dcd menu
      $('li[data-sub-menu-key="users"]').remove();
      $('li[data-sub-menu-key="customDCD"]').remove();

      sections = temeda.core.security.user.RestrictedAdminAccess
        ? temeda.core.security.user.RestrictedAdminAccess.split(",")
        : [];
      if (sections.length > 0) {
        const hideMenus = _.differenceWith(adminMenuSections, sections, _.isEqual);

        for (const menu of hideMenus) {
          //remove these menu options because they weren't passed from restrictedAdminAccess
          if (menu.substr(0, 7) === "account") {
            $(`li[data-sub-menu-key="${menu.substr(8)}"]`).remove();
          } else {
            $(`li[data-sub-menu-key="${menu}"]`).remove();
          }
        }
        //check if all the sub menus under account have been removed and if so also remove account
        var hierarchyMenuContainter = $('li[data-menu-key="account"] ul.lipstic-dropdown-menu li.sub-menu-item');
        if (!hierarchyMenuContainter.length) {
          $('li[data-menu-key="account"]').remove();
        }

        var AdminMenuContainter = $('li[data-menu-key="admin"] ul.lipstic-dropdown-menu li.sub-menu-item');
        if (!AdminMenuContainter.length) {
          $('li[data-menu-key="admin"]').remove();
        }
      }
    }

    //show or hide menus based upon RoleType
    switch (temeda.core.security.user.UserRole.idUserRole) {
      case temeda.enum.RoleType.RestrictedAdministrator: {
        // check for allow admin menus
        if (sections.length > 0 && sections[0] !== "") {
          $('li[data-menu-key="admin"]').show(); //only an Administrator can see the Account Menu
        } else {
          //remove if restricted admin and no sections available so it can't be shown from devtools
          $('li[data-menu-key="admin"]').remove();
          $('li[data-menu-key="account"]').remove(); // remove account menu
        }

        break;
      }
      case temeda.enum.RoleType.Administrator: {
        $('li[data-menu-key="admin"]').show(); //only an Administrator can see the Account Menu

        break;
      }
      case temeda.enum.RoleType.User:
      case temeda.enum.RoleType.Sales:
      case temeda.enum.RoleType.Service:
      case temeda.enum.RoleType.Technician: {
        $('li[data-menu-key="admin"]').remove(); //only an Administrator can see the Account Menu
        $('.accordion-item[data-accordion-key="unassigned"]').remove(); //only an Administrator can see the Unassinged Accordion drawer
        $('li[data-menu-key="account"]').remove(); // remove account menu
        break;
      }
      case temeda.enum.RoleType.TemedaAdministrator: {
        $('.menu-item[data-menu-key="temeda-admin-view"]').show();
        $('.menu-item[data-menu-key="temeda-dealer-view"]').remove();
        break;
      }
      case temeda.enum.RoleType.DealerAdministrator: {
        $('.menu-item[data-menu-key="temeda-admin-view"]').remove();
        $('.menu-item[data-menu-key="temeda-dealer-view"]').show();
        break;
      }
      default: {
        $('li[data-menu-key="admin"]').remove(); //remove account menu
        $('li[data-menu-key="account"]').remove(); // remove account menu
        $('.menu-item[data-menu-key="temeda-admin-view"]').remove(); //remove temeda admin menu
        $('.menu-item[data-menu-key="temeda-dealer-view"]').remove(); //remove temeda dealer admin menu
        break;
      }
    }

    temeda.core.security.user.camera = null;
    temeda.core.security.user.sensors = null;
    const enterprise = customLandingPage(temeda.core.security.user);
    temeda.core.security.user.enterprise = enterprise ? true : false;
    //if the user logged in as hierarchy level5 hide the account hierarchy menu
    if (temeda.core.security.user.idCustomerType === 5) {
      $("li[data-sub-menu-key='hierarchy'").remove(); //remove menu item until we have more items to show in account
    }

    //Camera and Refresh Interval from CustomerConfiguration
    temeda.core.security.user.CustomerConfigurations.forEach(function(setting) {
      if (setting.ConfigGroup === "refreshinterval") {
        if (temeda.core.webSocketClient) {
          temeda.core.webSocketClient.setRefreshInterval(parseInt(setting.ConfigSettings));
        }
        // } else if (setting.ConfigGroup === "CameraCount") {
        //   if (parseInt(setting.ConfigSettings) > 0) {
        //     //Show/Hide Camera Menu if account has cameras
        //     $('.sub-menu-item[data-sub-menu-key="cameras"]').show();
        //     temeda.core.security.user.camera = true;
        //   } else {
        //     temeda.core.security.user.camera = false;
        //     $('.sub-menu-item[data-sub-menu-key="cameras"]').remove();
        //   }
        // } else if (setting.ConfigGroup === "SensorCount") {
        //   if (parseInt(setting.ConfigSettings) > 0) {
        //     //Show/Hide Sensor Menu if account has sensors
        //     $('.sub-menu-item[data-sub-menu-key="sensors"]').show();
        //     temeda.core.security.user.sensors = true;
        //   } else {
        //     temeda.core.security.user.sensors = false;
        //     $('.sub-menu-item[data-sub-menu-key="sensors"]').remove();
        //   }
      } else if (setting.ConfigSettings === "AddDeleteAssetEditSerialNumber=true") {
        addDeleteAssetEditSerialnumber = true;
        // } else if (setting.ConfigGroup === "timesheets") {
        //   const splitter = setting.ConfigSettings ? setting.ConfigSettings.split("=") : null;
        //   let hasTimeSheet = null;
        //   if (splitter) {
        //     for (let i = 0; i < splitter.length; i++) {
        //       if (splitter[i] === "timesheets" && splitter[i + 1] === "true") {
        //         hasTimeSheet = true;
        //         $('.sub-menu-item[data-sub-menu-key="timesheet"]').show();
        //         temeda.core.webApi
        //           .asyncGet("timesheets/asset")
        //           .then(returnObject => {
        //             // console.log(returnObject);
        //             temeda.core.security.user.timesheet = returnObject;
        //           })
        //           .fail(() => {
        //             temeda.core.security.user.timesheet = null;
        //             $('.sub-menu-item[data-sub-menu-key="timesheet"]').remove();
        //           });
        //       }
        //     }
        //     if (!hasTimeSheet) {
        //       $('.sub-menu-item[data-sub-menu-key="timesheet"]').remove();
        //     }
        //   }
      } else if (setting.ConfigGroup === "custommenu") {
        const menus = JSON.parse(setting.ConfigSettings) || null;
        if (window.location.pathname === "/opening.html" && menus && menus.maintitle === "Cmd Console") {
          document.getElementById("command-console-links").classList.remove("hidden");
          // const customMenu = createMenu(menus);
          // const navBar = document.getElementsByClassName("primary-navigation-list");
          // var list = navBar[0];
          // list.insertBefore(customMenu, list.childNodes[0]);
        }

        if (
          window.location.pathname === "/opening.html" &&
          menus &&
          menus.maintitle === "TotalELD" &&
          temeda.core.security.user.UserRole.idUserRole === temeda.enum.RoleType.Administrator
        ) {
          document.getElementById("eld-links").classList.remove("hidden");
          document.querySelector("#eld-links a").href = menus.mainlink;
        } else {
          $('.menu-item[data-menu-key="eldlink"]').remove();
        }
      }
    });

    setupPermissions();
  }

  return {
    homepage: function(user) {
      return setup_homepage(user);
    },
    impersonateThisUser: function(idUserRole) {
      return setup_impersonateThisUser(idUserRole);
    },
    return_homepage,
    customLandingPage,
    permissions: setupPermissions,
    getPermissions: getPermissions(),
    init: init,
  };
})(jQuery);

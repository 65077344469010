var temeda = temeda || {}; // eslint-disable-line no-use-before-define
temeda.core = temeda.core || {};

temeda.core.webApi = (function($) {
  "use strict";

  var basePath = "./api/";

  // if (window.location.host.indexOf('localhost:3010') < 0) {
  //default gulp dev/broswer sync runs on port localhost:3000, so use normal basepath for reverse proxy on apache server
  // basePath = './api/';
  //basePath = 'http://10.46.89.24/admin/';
  //basePath = "http://localhost:49192/";
  // basePath = "http://10.28.71.57/admin/";
  // basePath = "https://www.mcdev.temeda.com/api/";
  // basePath = "https://webservices.mcdev2.temeda.com/admin/";
  // } else {
  //DEBUG MODE: running on local dev machine localhost:3010, so set path to your local webadmin api
  //NOTE: this is the ipv4 address from your local area connection (not vpn) - use command prompt 'ipconfig' to get your local ip
  //also, for local debugging of api, to allow vagrant box to see local webservices,
  //add the following line to c:/source/temeda/tsb/.vs/config/applicationhost.config :
  // AFTER <binding protocol="http" bindingInformation="*:49192:localhost" />
  // ADD <binding protocol="http" bindingInformation="*:49192:10.223.10.233" />
  // also, your local vagrant file must be modified as follows:
  //#      echo '    ProxyPass /api https://www.mcdev.temeda.com/api' >> mrm.local.conf
  //#      echo '    ProxyPassReverse /api https://www.mcdev.temeda.com/api' >> mrm.local.conf
  //    echo '    ProxyPass /api http://10.223.10.233:49192/' >> mrm.local.conf
  //    echo '    ProxyPassReverse /api http://10.223.10.233:49192/' >> mrm.local.conf
  // vagrantfile change will require a 'vagrant provision' and 'vagrant up'
  // then run gulp dev-debug to run browsersync on localhost:3010
  //basePath = 'http://10.223.10.115:49192/';
  // basePath = 'http://10.223.10.115:49192/';
  // }

  function apiBasePath(path) {
    if (path !== undefined) {
      basePath = path;
    } else {
      return basePath;
    }
  }

  function ajaxGet(path, options) {
    var getOptions = options || {};
    getOptions.type = "GET";
    getOptions.cache = false;

    if (getOptions.data !== undefined) {
      getOptions.data.f = "json";
    } else {
      getOptions.data = {
        f: "json",
      };
    }

    return ajax(path, getOptions);
  }

  function ajaxGetSync(path, options) {
    var getOptions = options || {};
    getOptions.type = "GET";
    getOptions.cache = false;
    getOptions.async = false;

    if (getOptions.data !== undefined) {
      getOptions.data.f = "json";
    } else {
      getOptions.data = {
        f: "json",
      };
    }

    return ajax(path, getOptions);
  }

  function ajaxPost(path, options) {
    var postOptions = options || {};
    postOptions.type = "POST";
    postOptions.contentType = "application/json";

    if (postOptions.data) {
      postOptions.data = JSON.stringify(postOptions.data);
    }

    return ajax(path, postOptions);
  }

  function ajaxPut(path, options) {
    var putOptions = options || {};
    putOptions.type = "PUT";
    putOptions.contentType = "application/json";

    if (putOptions.data) {
      putOptions.data = JSON.stringify(putOptions.data);
    }

    return ajax(path, putOptions);
  }

  function ajaxPatch(path, options) {
    var patchOptions = options || {};
    patchOptions.type = "PATCH";
    patchOptions.contentType = "application/json";

    if (patchOptions.data) {
      patchOptions.data = JSON.stringify(patchOptions.data);
    }

    return ajax(path, patchOptions);
  }

  function ajaxDelete(path, options) {
    var deleteOptions = options || {};
    deleteOptions.type = "DELETE";
    deleteOptions.contentType = "application/json";

    if (deleteOptions.data) {
      deleteOptions.data = JSON.stringify(deleteOptions.data);
    }

    return ajax(path, deleteOptions);
  }

  function ajax(path, options) {
    // TODO: Remove this once we know ajaxSetup is no longer being set.
    $.ajaxSetup({
      async: null,
      beforeSend: null,
      complete: null,
      successs: null,
    });

    // options.url = 'http://10.46.89.24/admin/' + path;
    options.url = basePath + path;
    options.async = options.async === false ? false : true;
    options.headers = {
      "Access-Control-Allow-Origin": true,
    };

    if (temeda.core.security && temeda.core.security.credentials) {
      var credentials = temeda.core.security.credentials;
      if (credentials.isSsoUser) {
        options.headers.Authorization = "Bearer " + credentials.token;
        if (credentials.impersonate) {
          options.headers["X-Temeda-Impersonate"] = credentials.impersonate;
        }
      } else {
        var encodedCredentials = null;
        var decodeUsername = window.atob(credentials.username);
        var decodePassword = window.atob(credentials.password);

        if (credentials.username && credentials.password && credentials.impersonate) {
          encodedCredentials = window.btoa(decodeUsername + ":" + decodePassword + ":" + credentials.impersonate);
        } else if (credentials.username && credentials.password) {
          encodedCredentials = window.btoa(decodeUsername + ":" + decodePassword);
        }

        if (encodedCredentials) {
          options.headers.Authorization = "Basic " + encodedCredentials;
        }
      }
    }

    return $.ajax(options);
  }

  return {
    apiBasePath: apiBasePath,
    asyncGet: ajaxGet,
    syncGet: ajaxGetSync,
    asyncPost: ajaxPost,
    asyncPut: ajaxPut,
    asyncPatch: ajaxPatch,
    asyncDelete: ajaxDelete,
  };
})(jQuery);
